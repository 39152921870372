@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --h1-font-size: 80px;
    --h2-font-size: 48px;
    --h3-font-size: 36px;
    --h4-font-size: 32px;
    --h5-font-size: 24px;
    --h6-font-size: 22px;
    --p-font-size: 18px;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 900;
}

h2 {
    font-size: var(--h2-font-size);
    font-weight: var(--font-weight-bold);
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 640px) {
    .gradient::after {
        content: "";
        background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 0, 0.2) 20%, rgba(139, 0, 0, 1) 100%) repeat scroll 0 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.classroom-icon:hover {
    fill: yellow;
}